import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import moment from "moment";
import store from "./store";
import vSelect from "vue-select";
import VueSlider from "vue-slider-component";
import {
  interceptorsSetup,
  interceptorsResponse
} from "@/helpers/interceptors";
import LoadingOverlay from "@/LoadingOverlay";
import Loader from "@/Loader";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { setInteractionMode } from 'vee-validate';
import VueCropper from 'vue-cropperjs';
import SocialSharing from 'vue-social-sharing';
import { VueEditor } from "vue2-editor";
import InlineSvg from 'vue-inline-svg';

import 'cropperjs/dist/cropper.css';
import 'material-icons/iconfont/material-icons.css';
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'

import VueSplit from "vue-split-panel";
Vue.use(VueSplit);

setInteractionMode('eager');
// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.component("v-select", vSelect);
Vue.component("v-slider", VueSlider);
Vue.component("LoadingOverlay", LoadingOverlay);
Vue.component("Loader", Loader);
Vue.component("VueCropper", VueCropper);
Vue.component("VueEditor", VueEditor);
Vue.component('inline-svg', InlineSvg);

Vue.config.performance = true;
Vue.prototype.$moment = moment;
Vue.prototype.$log = console.log.bind(console)

Vue.use(CoreuiVue);
Vue.use(SocialSharing);



Vue.use(VueTour)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm A')
  }
});  

function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
extend("required", {
  validate(value) {
    let isValid;
    if (Array.isArray(value)) {
      isValid = value.length > 0;
    } else {
      isValid = ["", null, undefined].indexOf(value) === -1;
    }
    return {
      required: true,
      valid: isValid
    };
  },
  message: "This field is required",
  computesRequired: true
});

interceptorsSetup();
interceptorsResponse();

new Vue({
  el: "#app",
  router,
  icons,
  store,
  template: "<App/>",
  components: {
    App
  }
});
